(function ($) {

	var planes = [{
		lat: 49.0217,
		lon: 6.2435,
		title: 'Metz-Nancy Route de Vigny 57420 Goin France',
		html: '<p>Metz-Nancy Route de Vigny <br> 57420 Goin France </p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.62389,
		lon: 6.20278,
		title: 'Luxembourg-Findel Rue de Trèves 2632 Findel Luxembourg',
		html: '<p>Luxembourg-Findel Rue de Trèves <br> 2632 Findel Luxembourg </p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.2201,
		lon: 7.112169999999992,
		title: 'Sarrebruck Balthasar-Goldstein-Straße 66131 Saarbrücken Allemagne',
		html: '<p>Sarrebruck Balthasar-Goldstein-Straße  <br>66131 Saarbrücken Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.9446333,
		lon: 7.2612103,
		title: 'Aéroport Frankfurt-Hahn 55483 Hahn-Flughafen Allemagne',
		html: '<p>Aéroport Frankfurt-Hah, <br> 55483 Hahn-Flughafen Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.7808681,
		lon: 4.1962835,
		title: 'Rue Louis Blériot 51320 Bussy-Lettrée France',
		html: '<p>Rue Louis Blériot, <br> 51320 Bussy-Lettrée France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.2084465,
		lon: 4.153124,
		title: 'Aérodrome de Reims Prunay, D 931, 51360 Prunay, France',
		html: '<p>Aérodrome de Reims Prunay, D 931, <br> 51360 Prunay, France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.0342,
		lon: 8.553609999999935,
		title: 'Aéroport Frankfurt-Main 60547 Frankfurt Allemagne',
		html: '<p>Aéroport Frankfurt-Main, <br> 60547 Frankfurt Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.6302888,
		lon: 5.4388039,
		title: 'Rue de l\'Aéroport, 4460 Grâce-Hollogne, Belgique',
		html: '<p>Rue de l\'Aéroport, <br> 4460 Grâce-Hollogne, Belgique</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.4697339,
		lon: 4.4688153,
		title: 'Rue des Frères Wright, 6041 Charleroi, Belgique',
		html: '<p>Rue des Frères Wright, <br>  6041 Charleroi, Belgique</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.8837,
		lon: 4.472980000000007,
		title: 'Leopoldlaan 1930 Zaventem Belgique',
		html: '<p>Leopoldlaan 1930, <br> Zaventem Belgique</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 47.5996,
		lon: 7.531380000000013,
		title: 'Aéroport de Bâle-Mulhouse 68304 Saint-Louis France',
		html: '<p>Aéroport de Bâle-Mulhouse, <br> 68304 Saint-Louis France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.9564171,
		lon: 6.6309791,
		title: 'Kennedystraße 51147 Köln Allemagne',
		html: '<p>Kennedystraße, <br> 51147 Köln Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.6906124,
		lon: 9.1886931,
		title: 'Flughafenstraße 32 70629 Stuttgart Allemagne',
		html: '<p>Flughafenstraße 32, <br> 70629 Stuttgart Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 47.0386022,
		lon: 5.4129417,
		title: 'Rue de Dole, 39500 Tavaux, France',
		html: '<p>Rue de Dole, <br>  39500 Tavaux, France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.8177314,
		lon: 9.8970741,
		title: 'Wurtzbourg Am Schenkenturm 97080 Würzburg Allemagne',
		html: '<p>Wurtzbourg Am Schenkenturm , <br> 97080 Würzburg Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.007,
		lon: 2.559790000000021,
		title: 'Paris- Charles de Gaulle Aéroport Charles de Gaulle 95700 Roissy-en-France France',
		html: '<p>Aéroport Charles de Gaulle, <br> 95700 Roissy-en-France France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 51.5151879,
		lon: 7.6129258,
		title: 'Flughafenring 2 44319 Dortmund Allemagne',
		html: '<p>Flughafenring 2, <br> 44319 Dortmund Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 50.5791409,
		lon: 3.0947738,
		title: 'Route de l’Aéroport 59810 Lesquin France',
		html: '<p>Route de l’Aéroport, <br> 59810 Lesquin France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 47.4502636,
		lon: 8.5622284,
		title: 'Aéroport Zurich 8058 Zürich-Flughafen Suisse',
		html: '<p>Aéroport Zurich, <br> 8058 Zürich-Flughafen Suisse</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 51.2039428,
		lon: 2.8694683,
		title: 'Bruges Nieuwpoortsesteenweg 889 8400 Oostende Belgique',
		html: '<p>Bruges Nieuwpoortsesteenweg 889, <br> 8400 Oostende Belgique</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.5386526,
		lon: 7.6250099,
		title: 'Strasbourg Route de l’Aéroport – RD221 67960 Entzheim France',
		html: '<p>Strasbourg Route de l’Aéroport – RD221, <br> 67960 Entzheim France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-plane.png',
		animation: google.maps.Animation.DROP
	}];

	var trains = [{
		lat: 49.1098,
		lon: 6.176150000000007,
		title: '1 Place du Général de Gaulle 57000 Metz France',
		html: '<p>1 Place du Général de Gaulle <br>57000 Metz France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 48.9597,
		lon: 6.202829999999949,
		title: '910 Rd 913, Louvigny, Grand-Est, France',
		html: '<p>910 Rd 913, Louvigny, <br>Grand-Est, France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.3537,
		lon: 6.168549999999982,
		title: '10 Place de la Gare, Thionville, Grand-Est, France',
		html: '<p>10 Place de la Gare, <br> Thionville, Grand-Est, France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 48.6895543,
		lon: 6.1744748,
		title: '3 Place Thiers 54000 Nancy France',
		html: '<p>3 Place Thiers, <br> 54000 Nancy France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.1894,
		lon: 6.901659999999993,
		title: 'Place Robert Schuman 57600 Forbach France',
		html: '<p>Place Robert Schuman, <br> 57600 Forbach France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.5998483,
		lon: 6.1340346,
		title: '11 Place de la gare, 1616 Luxembourg Luxembourg',
		html: '<p>11 Place de la gare, <br> 1616 Luxembourg Luxembourg</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 49.240661,
		lon: 6.9914938,
		title: 'Am Hauptbahnhof 6 – 12 66111 Saarbrücken Allemagne',
		html: '<p>Am Hauptbahnhof 6 – 12, <br> 66111 Saarbrücken Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 48.5879,
		lon: 6.500149999999962,
		title: '2 place Pierre Sémard 54300 Lunéville France',
		html: '<p>2 place Pierre Sémard, <br> 54300 Lunéville France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}, {
		lat: 48.9672,
		lon: 5.2874,
		title: 'Meuse TGV Lieu dit Le Cugnet Mondrecourt 55220 Les Trois-Domaines France',
		html: '<p>Meuse TGV Lieu dit Le Cugnet Mondrecourt , <br> 55220 Les Trois-Domaines France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.7378369,
		lon: 7.052833,
		title: 'Place de la gare 57400 Sarrebourg France',
		html: '<p>Place de la gare, <br> 57400 Sarrebourg France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.7443,
		lon: 7.362449999999967,
		title: '1 place de la gare 67700 Saverne France',
		html: '<p>1 place de la gare, <br> 67700 Saverne France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.1786,
		lon: 6.44214999999997,
		title: 'Place Général de Gaulle 88000 Epinal France',
		html: '<p>Place Général de Gaulle, <br> 88000 Epinal France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.7729,
		lon: 5.166220000000067,
		title: 'Place de la république 55000 Bar-le-Duc France',
		html: '<p>Place de la république, <br> 55000 Bar-le-Duc France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.4360974,
		lon: 7.76796,
		title: 'Bahnhofstr. 1 67655 Kaiserslautern Allemagne',
		html: '<p>Bahnhofstr. 1, <br> 67655 Kaiserslautern Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.281865,
		lon: 6.9481371,
		title: 'Place Pierre Semard 88100 Saint-Dié-des-Vosges France',
		html: '<p>Place Pierre Semard, <br> 88100 Saint-Dié-des-Vosges France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.9547,
		lon: 4.348569999999995,
		title: 'Place de la gare 51000 Châlons-en-Champagne France',
		html: '<p>Place de la gare, <br> 51000 Châlons-en-Champagne France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.5843,
		lon: 7.733979999999974,
		title: '20 Place de la Gare 67000 Strasbourg France',
		html: '<p>20 Place de la Gare, <br> 67000 Strasbourg France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 48.7184,
		lon: 4.586950000000002,
		title: 'Place de la gare 51300 Vitry-le-François France',
		html: '<p>Place de la gare, <br> 51300 Vitry-le-François France</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	},{
		lat: 49.4796632,
		lon: 8.4698178,
		title: 'Willy-Brandt-Platz 17 68161 Mannheim Allemagne',
		html: '<p>Willy-Brandt-Platz 17, <br> 68161 Mannheim Allemagne</p>',
		show_infowindow: true,
		icon: '/app/themes/belgatrans/dist/images/picto-train.png',
		animation: google.maps.Animation.DROP
	}]

	if ($("#gmap").length) {
		$(function () {
			carte = new Maplace({
				map_div: '#gmap',
				map_options: {
					zoom: 6,
					scrollwheel: false,
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
				},

				styles: {
					'Greyscale': [
					{
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#f5f5f5"
						}
						]
					},
					{
						"elementType": "labels.icon",
						"stylers": [
						{
							"visibility": "off"
						}
						]
					},
					{
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#616161"
						}
						]
					},
					{
						"elementType": "labels.text.stroke",
						"stylers": [
						{
							"color": "#f5f5f5"
						}
						]
					},
					{
						"featureType": "administrative.land_parcel",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#bdbdbd"
						}
						]
					},
					{
						"featureType": "poi",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#eeeeee"
						}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#757575"
						}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#e5e5e5"
						}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#9e9e9e"
						}
						]
					},
					{
						"featureType": "road",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#ffffff"
						}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#757575"
						}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#dadada"
						}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#616161"
						}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#9e9e9e"
						}
						]
					},
					{
						"featureType": "transit.line",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#e5e5e5"
						}
						]
					},
					{
						"featureType": "transit.station",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#eeeeee"
						}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [
						{
							"color": "#c9c9c9"
						}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.fill",
						"stylers": [
						{
							"color": "#9e9e9e"
						}
						]
					}
					]
				},
				locations: [{
					lat: 49.0217,
					lon: 6.2435,
				}],
				show_markers: false,
				controls_on_map: false
			}).Load();
		});
	}


	var gmapTrainListIsActive = false;
	var gmapAirportListIsActive = false;

	$('#gmap-train').on('click', function() {
		carte.SetLocations(trains);
		if (!carte.o.show_markers) {
			carte.o.show_markers = true;
		}
		if (!gmapTrainListIsActive) {
			$('.gmap-train-list').toggle();
			gmapTrainListIsActive = true;
		}
		if (gmapAirportListIsActive) {
			$('.gmap-airport-list').toggle();
			gmapAirportListIsActive = false;
		}
		carte.Load();
	});

	$('#gmap-airport').on('click', function() {
		carte.SetLocations(planes);
		if (!carte.o.show_markers) {
			carte.o.show_markers = true;
		}
		if (!gmapAirportListIsActive) {
			$('.gmap-train-list').toggle();
			gmapAirportListIsActive = true;
		}
		if (gmapTrainListIsActive) {
			$('.gmap-airport-list').toggle();
			gmapTrainListIsActive = false;
		}
		carte.Load();
	});

})(jQuery);